import { format as formatDate } from "date-fns";

export function toTimeString(
  hours: number,
  minutes: number,
  seconds: number,
  format: string
): string {
  return formatDate(new Date(0, 0, 0, hours, minutes, seconds), format);
}
