import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";

export class PlaceOptionsModel implements OrderOptionsItemInterface {
  cargoCategories: string[];
  cargoSubCategory: string;
  loadingTypes: string[];
  bodyTypes: string[];
  bodyTypeComment: string;
  temperatureRegimes: string[];
  cargoCondition: string;
  cargoShippingTitle: string;
  cargoType: string;
  containerType: string;
  packingMethod: string;
  dangerousClassCode: string;

  constructor() {
    this.cargoCategories = [];
    this.cargoSubCategory = "";
    this.loadingTypes = [];
    this.bodyTypes = [];
    this.bodyTypeComment = "";
    this.temperatureRegimes = [];
    this.cargoType = "";
    this.cargoCondition = "";
    this.cargoShippingTitle = "";
    this.dangerousClassCode = "";
    this.containerType = "";
    this.packingMethod = "";
  }
}
