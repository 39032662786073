import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import { PersonType } from "@/models/person/Person.interface";

export interface LoaderItemInterface extends PersonItemInterface {
  isSender: boolean;
  reasonToAct: LoaderItemContractDataInterface;
  employee: LoaderItemEmployeeInterface;
  contacts: ContactItemInterface[];

  addContact(): void;
  removeContact(contactIdx: number): void;
}

export interface LoaderItemEmployeeInterface {
  fullName: string;
  position: string;
  authorityToLoad: AuthorityToLoadEnum;
  contractData: LoaderItemContractDataInterface;
}

export interface LoaderItemContractDataInterface {
  title: string;
  number: string;
  contractDate: string;
  parties: LoaderItemContractPartyInterface[];
}

export interface LoaderItemContractPartyInterface {
  inn: string;
  type: PersonType;
}

export enum AuthorityToLoadEnum {
  JobResponsibilities,
  Other
}
