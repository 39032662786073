import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { PersonType } from "@/models/person/Person.interface";

export default class PersonItemModel implements PersonItemInterface {
  firstName: string;
  fullName: string;
  id: number;
  inn: string;
  ogrn: string;
  kpp: string;
  lastName: string;
  middleName: string;
  name: string;
  address: string;
  type: PersonType;
  countryCode: string;

  constructor(person?: PersonItemInterface) {
    this.firstName = person?.firstName ?? "";
    this.middleName = person?.middleName ?? "";
    this.lastName = person?.lastName ?? "";
    this.fullName = person?.fullName ?? "";
    this.name = person?.name ?? "";
    this.id = person?.id ?? 0;
    this.inn = person?.inn ?? "";
    this.ogrn = person?.ogrn ?? "";
    this.kpp = person?.kpp ?? "";
    this.type = person?.type ?? PersonType.COMPANY;
    this.address = person?.address ?? "";
    this.countryCode = person?.countryCode ?? "RUS";
  }

  clearData() {
    this.lastName = "";
    this.firstName = "";
    this.middleName = "";
    this.fullName = "";
    this.id = 0;
    this.inn = "";
    this.kpp = "";
    this.ogrn = "";
    this.name = "";
    this.type = PersonType.COMPANY;
    this.address = "";
  }

  updateData(data: PersonItemInterface) {
    this.clearData();
    this.id = data.id;
    this.countryCode = data.countryCode;

    if (data.type === PersonType.COMPANY) {
      this.type = PersonType.COMPANY;
      this.name = data.name;
      this.inn = data.inn;
      this.address = data.address;
      this.ogrn = data.ogrn;
      this.kpp = data.kpp;
      return;
    }

    this.type = data.type;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.fullName = data.fullName;
    if (data.type === PersonType.INDIVIDUAL) return;

    this.inn = data.inn;
    this.ogrn = data.ogrn;
    this.address = data.address;
  }

  getFullName(): string {
    return (
      this.fullName || `${this.lastName} ${this.firstName} ${this.middleName}`
    );
  }
}
