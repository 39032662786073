import OrderItemModel from "@/models/order/OrderItem.model";
import { ImportOrderListItemInterface } from "@/models/import/ImportOrderListItem.interface";

export default class ImportOrderListItemModel extends OrderItemModel
  implements ImportOrderListItemInterface {
  removed: boolean;

  constructor() {
    super();

    this.removed = false;
  }

  remove() {
    this.removed = true;
  }

  recover() {
    this.removed = false;
  }
}
