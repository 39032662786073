import { compose, map, multiply, pick, reduce } from "lodash/fp";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import convertStringToNumber from "@/utils/convertStringToNumber";
import { NumberHelper } from "@/utils/Number.helper";

export default class DimensionsFormModel implements DimensionsFormInterface {
  height: string;
  length: string;
  volume: string;
  weight: string;
  width: string;

  constructor() {
    this.height = "";
    this.length = "";
    this.volume = "";
    this.width = "";
    this.weight = "";
  }

  static calcVolume(dimensions: DimensionsFormInterface): string {
    const dimensionsValues: number[] = compose(
      map(convertStringToNumber),
      pick(["height", "length", "width"])
    )(dimensions);

    return NumberHelper.numberToString(
      reduce(multiply, 1)(dimensionsValues) * 1e-6
    );
  }
}
