import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import PersonItemModel from "@/models/person/PersonItem.model";
import DimensionsFormModel from "@/models/order/DimensionsForm.model";
import OrderOptionsItemModel from "@/models/order/OrderOptionsItem.model";
import PlaceItemModel from "@/models/order/PlaceItem.model";
import {
  ExecutorOrganizationInterface,
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";
import { TemplateFormInterface } from "@/models/store/TemplatesTableHeader.interface";
import { ConditionsInterface } from "@/models/order/Conditions.interface";
import { ConditionsModel } from "@/models/order/Conditions.model";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import { generateId } from "@/utils/generateId";
import { RouteInterface } from "@/models/order/Route.interface";
import LoadingAddressObjectModel from "@/models/order/LoadingAddressObject.model";
import {
  AddressObjectInterface,
  LoadingAddressObjectInterface
} from "@/models/order/AddressObject.interface";
import AddressObjectModel from "@/models/order/AddressObject.model";

export default class OrderItemModel implements OrderItemInterface {
  externalId: string;
  executorRoleType: ExecutorRoleTypeEnum;
  isAuctionAutoComplete: boolean;
  isPriceOnlyDescend: boolean;
  isMinimumBidHidden: boolean;
  auctionBidStep: string;
  auctionTime: string;
  assessedValue: string;
  isAuction: boolean;
  isAuctionAutoExtend: boolean;
  isRouteOrderError: boolean;
  auctionExtendTime: string;
  auctionExtendActivationTime: string;
  extendTimeLimit: string;
  comment: string;
  customer: PersonItemInterface;
  dimensions: DimensionsFormInterface;
  options: OrderOptionsItemInterface;
  places: PlaceItemInterface[];
  route: RouteInterface;
  price: string;
  tax: string;
  type: string;
  orderType: OrderTypeEnum;
  forwardToOrganizations: OrganizationViewInterface[] | null;
  forwardToTypeOrganization: number;
  forwardedPrice: string;
  executorOrganization: ExecutorOrganizationInterface | null;
  conditions: ConditionsInterface;
  hasEdm: boolean;
  template: TemplateFormInterface | null;
  internalId: string;

  constructor() {
    this.externalId = "";
    this.internalId = generateId();
    this.assessedValue = "";
    this.comment = "";
    this.price = "";
    this.tax = "";
    this.type = "Ftl";
    this.customer = new PersonItemModel();
    this.dimensions = new DimensionsFormModel();
    this.options = new OrderOptionsItemModel();
    this.places = [new PlaceItemModel()];
    this.route = {};
    this.executorRoleType = ExecutorRoleTypeEnum.Any;
    this.orderType = OrderTypeEnum.ToYourself;
    this.forwardToOrganizations = null;
    this.forwardToTypeOrganization = 0;
    this.forwardedPrice = "";
    this.auctionTime = "";
    this.auctionBidStep = "";
    this.isAuction = false;
    this.isAuctionAutoExtend = false;
    this.isRouteOrderError = false;
    this.isMinimumBidHidden = false;
    this.isAuctionAutoComplete = false;
    this.isPriceOnlyDescend = false;
    this.auctionExtendTime = "";
    this.auctionExtendActivationTime = "";
    this.extendTimeLimit = "";
    this.hasEdm = false;

    this.executorOrganization = null;
    this.conditions = new ConditionsModel(this);
    this.template = null;
  }

  addPlace() {
    this.places.push(new PlaceItemModel());
  }

  addPlaceWithLoadingAddress(
    loadingAddress: LoadingAddressObjectInterface,
    indexToAdd: number
  ) {
    const newPlace = new PlaceItemModel();
    newPlace.loadingAddress = new LoadingAddressObjectModel(
      undefined,
      loadingAddress.address,
      loadingAddress.person
    );
    this.places.splice(indexToAdd, 0, newPlace);
  }

  addPlaceWithUnloadingAddress(
    unloadingAddress: AddressObjectInterface,
    indexToAdd: number
  ) {
    const newPlace = new PlaceItemModel();
    newPlace.unloadingAddress = new AddressObjectModel(
      undefined,
      unloadingAddress.address,
      unloadingAddress.person
    );
    this.places.splice(indexToAdd, 0, newPlace);
  }

  removePlace(placeIdx: number) {
    this.places.splice(placeIdx, 1);
  }
}
