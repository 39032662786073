import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import { getExecutorOrganizations } from "@/api/order";
import { InsuranceResponseInterface } from "@/models/order/InsuranceResponse";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import {
  getTemplateData,
  removeTemplate,
  renameTemplate,
  saveTemplate,
  updateTemplate
} from "@/api/user";
import {
  SaveOrUpdateTemplateRequestInterface,
  SaveOrUpdateTemplateResponseInterface
} from "../api/template/SaveOrUpdateTemplateRequestInterface";
import { TemplateFormInterface } from "../store/TemplatesTableHeader.interface";
import {
  ExecutorOrganizationInterface,
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "../global/OrganizationView.interface";
import ImportService from "../import/Import.service";
import OrderService from "./Order.service";
import { TemplateServiceInterface } from "./Template.service.interface";
import { RemoveTemplateRequestInterface } from "../api/template/RemoveTemplateRequest.interface";
import { RenameTemplateRequestInterface } from "../api/template/RenameTemplateRequestInterface";
import { OrderRequestInterface } from "@/models/order/OrderRequest.interface";
import { ImportOrderListItemInterface } from "@/models/import/ImportOrderListItem.interface";
import ImportOrderListItemModel from "@/models/import/ImportOrderListItem.model";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import convertStringToNumber from "@/utils/convertStringToNumber";
import { Store } from "pinia";
import {
  UserActionsInterface,
  UserGettersInterface,
  UserStateInterface
} from "@/models/store/UserState.interface";
import { useUserStore } from "@/store/useUserStore";

export default class TemplateService implements TemplateServiceInterface {
  private _templateRequestAbortController: AbortController | null = null;
  private _importService: ImportService;
  private _orderService: OrderService;
  private _userStore: Store<
    "user",
    UserStateInterface,
    UserGettersInterface,
    UserActionsInterface
  >;

  constructor() {
    this._orderService = new OrderService();
    this._userStore = useUserStore();
    this._importService = new ImportService();
  }

  async mapOrderRequest(
    order: OrderRequestInterface,
    executorOrganizations: ExecutorOrganizationInterface[] | null,
    forwardToOrganizationsByExecutorLineClient: Record<
      string,
      OrganizationViewInterface[]
    >
  ): Promise<ImportOrderListItemInterface> {
    const orderItem = new ImportOrderListItemModel();

    await ImportService.fillOrderItem(orderItem, order);

    if (order.options !== null)
      await ImportService.fillOrderItemOptions(
        orderItem.options,
        order.options
      );

    await ImportService.fillPerson(orderItem.customer, order.customer);

    if (!order.executorLineClientId) {
      orderItem.executorOrganization =
        executorOrganizations && executorOrganizations.length > 0
          ? executorOrganizations[0]
          : null;
    } else {
      orderItem.executorOrganization =
        (executorOrganizations &&
          executorOrganizations.find(
            o => o.id === order.executorLineClientId
          )) ||
        null;
    }

    this._userStore.SET_OWNER_COUNTRY(
      orderItem.executorOrganization?.countryCode ?? ""
    );

    orderItem.places = await this._importService.fillOrderItemPlaces(
      order.places,
      order.type
    );

    if (orderItem.options.fillFullVolume) {
      Object.keys(orderItem.dimensions).forEach(dimName => {
        const sum = orderItem.places.reduce((sum, place) => {
          return sum + convertStringToNumber(place.dimensions[dimName]);
        }, 0);

        this._orderService.setDimensionToOrder(
          orderItem,
          dimName,
          String(sum),
          false
        );
      });
    } else {
      orderItem.places.forEach((place, idx) => {
        Object.keys(place.dimensions).forEach(dimName => {
          if (
            convertStringToNumber(
              place.dimensions[dimName as keyof DimensionsFormInterface]
            ) === 0
          )
            return;
          this._orderService.setDimensionToOrderPlace(
            orderItem,
            idx,
            dimName as keyof DimensionsFormInterface,
            place.dimensions[dimName as keyof DimensionsFormInterface]
          );
        });
      });
    }

    if (order.options?.fillFullAssessedValue) {
      let sum = 0;
      orderItem.places.map(
        item => (sum += convertStringToNumber(item.assessedValue))
      );

      this._orderService.setAssessedValueToOrder(orderItem, String(sum));
    } else {
      orderItem.places.forEach((place, idx) => {
        this._orderService.setAssessedValueToOrderPlace(
          orderItem,
          idx,
          place.assessedValue
        );
      });
    }

    orderItem.conditions.otherConditions = order.otherConditionsText;

    switch (order.executorRoleType) {
      case "0":
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Any;
        break;
      case "1":
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Forwarding;
        break;
      case "2":
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Delivery;
        break;
      default:
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Any;
    }

    if (order.isForwarded)
      await ImportService.fillOrderItemFields(
        orderItem,
        order,
        forwardToOrganizationsByExecutorLineClient
      );

    this._orderService.setPriceToOrder(orderItem, orderItem.price);

    return orderItem;
  }

  async saveTemplateAs(
    orderItem: OrderItemInterface,
    insuranceData: {
      withInsurance: boolean;
      orderGuid: string;
      insuranceInfo: InsuranceResponseInterface | null;
    } = { withInsurance: false, orderGuid: "", insuranceInfo: null },
    isFtl = true,
    templateId: string | null,
    templateName: string
  ): Promise<SaveOrUpdateTemplateResponseInterface | ErrorResponseInterface> {
    const req = await this._orderService.mapOrderRequest(
      orderItem,
      insuranceData,
      isFtl
    );

    const command: SaveOrUpdateTemplateRequestInterface = {
      id: templateId,
      name: templateName,
      orderRequest: req
    };

    return command.id
      ? await updateTemplate(command)
      : await saveTemplate(command);
  }

  async loadTemplate(
    template: TemplateFormInterface,
    templates: TemplateFormInterface[]
  ): Promise<{
    templateData: OrderItemInterface | null;
    isSuccess: boolean;
    message: string;
  }> {
    this._templateRequestAbortController?.abort();
    this._templateRequestAbortController = new AbortController();

    const orderTemplateResponse = await getTemplateData(
      template.id,
      this._templateRequestAbortController?.signal
    );

    if (!orderTemplateResponse.isSuccess) {
      return {
        isSuccess: false,
        message: orderTemplateResponse.message,
        templateData: null
      };
    }

    const executorOrganizations = (await getExecutorOrganizations()).entity;
    const forwardToOrganizationsByExecutorLineClient: Record<
      string,
      OrganizationViewInterface[]
    > = {};

    const orderValue = await this.mapOrderRequest(
      orderTemplateResponse.entity,
      executorOrganizations,
      forwardToOrganizationsByExecutorLineClient
    );
    orderValue.template = templates.find(x => x.id == template.id) ?? null;

    return { isSuccess: true, message: "", templateData: orderValue };
  }

  async removeTemplate(
    command: RemoveTemplateRequestInterface
  ): Promise<SaveOrUpdateTemplateResponseInterface | ErrorResponseInterface> {
    return await removeTemplate(command);
  }

  async renameTemplate(
    command: RenameTemplateRequestInterface
  ): Promise<SaveOrUpdateTemplateResponseInterface | ErrorResponseInterface> {
    return await renameTemplate(command);
  }
}
