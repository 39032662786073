import { OrderRequestInterface } from "@/models/order/OrderRequest.interface";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import { DataResponseInterface } from "@/models/api/DataResponse.interface";
import api from "./baseApi";
import baseApi from "./baseApi";
import { HistoryOrderInterface } from "@/models/order/History.interface";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  ExecutorOrganizationInterface,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";
import { GetDefaultConditionsResponseInterface } from "@/models/forwarding/GetDefaultConditionsResponse.interface";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import { UploadOperationProgressInterface } from "@/models/order/UploadOperationProgress.interface";
import { PersonInterface } from "@/models/person/Person.interface";
import { OrganizationGroupInterface } from "@/models/OrganizationGroup/OrganizationGroup.interface";

export async function createOrder(
  req: OrderRequestInterface
): Promise<ErrorResponseInterface | DataResponseInterface<string>> {
  const res: AxiosResponse<
    ErrorResponseInterface | DataResponseInterface<string>
  > = await api.post("/Order/WithProgress", req);

  return res.data;
}

export async function getOrderUploadOperationProgress(
  operationId: string
): Promise<
  | ErrorResponseInterface
  | DataResponseInterface<UploadOperationProgressInterface>
> {
  const res: AxiosResponse<
    | ErrorResponseInterface
    | DataResponseInterface<UploadOperationProgressInterface>
  > = await api.get(`/Order/uploadProgress/${operationId}`);
  return res.data;
}

export async function createOrders(
  req: OrderRequestInterface[]
): Promise<ErrorResponseInterface | DataResponseInterface<string>> {
  const res: AxiosResponse<
    ErrorResponseInterface | DataResponseInterface<string>
  > = await api.post("/Order/Multiple", req);

  return res.data;
}

export async function getOrderList(): Promise<
  ErrorResponseInterface | DataResponseInterface<HistoryOrderInterface[]>
> {
  const res = await api.get("/Order/list");

  return res.data;
}

export async function importFile(
  formData: FormData
): Promise<
  ErrorResponseInterface | DataResponseInterface<OrderRequestInterface[]>
> {
  const res: AxiosResponse<
    ErrorResponseInterface | DataResponseInterface<OrderRequestInterface[]>
  > = await api.post("/Order/Import", formData);

  return res.data;
}

export async function getExecutorOrganizations(): Promise<
  | DataResponseInterface<ExecutorOrganizationInterface[]>
  | ErrorResponseInterface
> {
  const res: AxiosResponse<
    | DataResponseInterface<ExecutorOrganizationInterface[]>
    | ErrorResponseInterface
  > = await baseApi.get("/info/organizations-executors");

  return res.data;
}

export async function getOrganizationsToForwardOrder(
  id: number,
  executorOrgId: string,
  searchQuery: string,
  onlyPartners: boolean,
  signal?: AbortSignal
): Promise<
  DataResponseInterface<OrganizationViewInterface[]> | ErrorResponseInterface
> {
  const res: AxiosResponse<
    DataResponseInterface<OrganizationViewInterface[]> | ErrorResponseInterface
  > = await baseApi.get(
    `/info/organizations-to-forward-order?orderType=${id}&executorLineClientId=${executorOrgId}&searchQuery=${searchQuery}&onlyPartners=${onlyPartners}`,
    { signal: signal } as AxiosRequestConfig
  );

  if (signal?.aborted) {
    const err = new Error("Abort message");
    err.name = "AbortError";
    throw err;
  }

  return res.data;
}

export async function getDefaultConditions(): Promise<
  | DataResponseInterface<GetDefaultConditionsResponseInterface>
  | ErrorResponseInterface
> {
  const res: AxiosResponse<
    | DataResponseInterface<GetDefaultConditionsResponseInterface>
    | ErrorResponseInterface
  > = await baseApi.get("/info/condition-defaults");

  return res.data;
}

export async function getConditionsForGenContractId(
  genContractId: string
): Promise<
  | DataResponseInterface<GetDefaultConditionsResponseInterface>
  | ErrorResponseInterface
> {
  const res: AxiosResponse<
    | DataResponseInterface<GetDefaultConditionsResponseInterface>
    | ErrorResponseInterface
  > = await baseApi.get(
    `/info/gen-contract-conditions?genContractId=${genContractId}`
  );

  return res.data;
}

export async function getDefaultPlaceOwner(): Promise<
  DataResponseInterface<PersonInterface> | ErrorResponseInterface
> {
  return (await baseApi.get(`/info/default-place-owner`)).data;
}

export async function postDefaultPlaceOwner(
  sender: OrderRequestInterface[]
): Promise<DataResponseInterface | ErrorResponseInterface> {
  return (await baseApi.post(`/info/default-place-owner`, sender)).data;
}

export async function geocodeAddress(
  query: string
): Promise<YandexMapAddressItemInterface[]> {
  const res: AxiosResponse<YandexMapAddressItemInterface[]> = await baseApi.get(
    `/info/geoCoder?query=${query}`
  );

  return res.data;
}

export async function getOrganizationGroupForOrganization(
  executorRoleType: number,
  executorOrgId: string,
  onlyPartners: boolean
): Promise<DataResponseInterface<OrganizationGroupInterface[]>> {
  const res: AxiosResponse<DataResponseInterface<
    OrganizationGroupInterface[]
  >> = await baseApi.get(
    `/info/groups-to-forward-order?ExecutorRoleType=${executorRoleType}&ExecutorLineClientId=${executorOrgId}&OnlyPartners=${onlyPartners}`
  );
  return res.data;
}
