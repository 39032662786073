import {
  LoaderItemContractDataInterface,
  LoaderItemEmployeeInterface,
  LoaderItemInterface
} from "@/models/person/LoaderItem.interface";
import PersonItemModel from "@/models/person/PersonItem.model";
import { LoaderEmployeeModel } from "@/models/person/LoaderEmployee.model";
import { LoaderContractDataModel } from "@/models/person/LoaderContractData.model";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import ContactItemModel from "@/models/order/ContactItem.model";

export class LoaderItemModel extends PersonItemModel
  implements LoaderItemInterface {
  employee: LoaderItemEmployeeInterface;
  isSender: boolean;
  reasonToAct: LoaderItemContractDataInterface;
  contacts: ContactItemInterface[];

  constructor() {
    super();
    this.employee = new LoaderEmployeeModel();
    this.isSender = false;
    this.reasonToAct = new LoaderContractDataModel();
    this.contacts = [new ContactItemModel()];
  }

  addContact() {
    this.contacts.push(new ContactItemModel());
  }
  removeContact(contactIdx: number): void {
    if (this.contacts.length <= 1) return;

    this.contacts.splice(contactIdx, 1);
  }
}
