import { YandexMapAddressItemDataInterface } from "@/models/yandex-map/YandexMapAddressItemData.interface";

export class YandexMapAddressItemDataModel
  implements YandexMapAddressItemDataInterface {
  area: string;
  country: string;
  countryCode: string;
  district: string;
  fullAddress: string;
  house: string;
  latitude: string;
  locality: string;
  longitude: string;
  province: string;
  street: string;
  addressIndex: number;

  constructor(data?: YandexMapAddressItemDataInterface) {
    this.area = data?.area ?? "";
    this.country = data?.country ?? "";
    this.countryCode = data?.countryCode ?? "";
    this.district = data?.district ?? "";
    this.fullAddress = data?.fullAddress ?? "";
    this.house = data?.house ?? "";
    this.latitude = data?.latitude ?? "";
    this.longitude = data?.longitude ?? "";
    this.locality = data?.locality ?? "";
    this.province = data?.province ?? "";
    this.street = data?.street ?? "";
    this.addressIndex = data?.addressIndex ?? 0;
  }
}
