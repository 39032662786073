import { ContactItemInterface } from "@/models/order/ContactItem.interface";

export default class ContactItemModel implements ContactItemInterface {
  fullName: string;
  phone: string;
  countryCode: string;

  constructor() {
    this.fullName = "";
    this.phone = "";
    this.countryCode = "RUS";
  }
}
