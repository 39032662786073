export class NumberHelper {
  /**
   * Корректировка округления десятичных дробей.
   *
   * @param type  Тип корректировки.
   * @param value Число.
   * @param exp   Показатель степени (десятичный логарифм основания корректировки).
   * @return Скорректированное значение.
   */
  private static decimalAdjust(
    type: "round" | "floor" | "ceil",
    value: number,
    exp: number
  ): number {
    // Если степень не определена, либо равна нулю...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }

    // Если значение не является числом, либо степень не является целым числом...
    if (isNaN(value) || !Number.isInteger(exp)) {
      return NaN;
    }
    // Сдвиг разрядов
    let valueStringParts = value.toString().split("e");
    value = Math[type](
      +(
        valueStringParts[0] +
        "e" +
        (valueStringParts[1] ? +valueStringParts[1] - exp : -exp)
      )
    );
    // Обратный сдвиг
    valueStringParts = value.toString().split("e");
    return +(
      valueStringParts[0] +
      "e" +
      (valueStringParts[1] ? +valueStringParts[1] + exp : exp)
    );
  }

  static round(value: number, exp: number) {
    return NumberHelper.decimalAdjust("round", value, exp);
  }
  static floor(value: number, exp: number) {
    return NumberHelper.decimalAdjust("floor", value, exp);
  }
  static ceil(value: number, exp: number) {
    return NumberHelper.decimalAdjust("ceil", value, exp);
  }

  static numberToString(num: number): string {
    return NumberHelper.round(num, -2).toString();
  }
  static toLocaleNumericString(
    value: number,
    locale = "ru-RU",
    options: Intl.NumberFormatOptions = { minimumFractionDigits: 2 }
  ): string {
    return value.toLocaleString(locale, options);
  }
}
