export interface PersonInterface {
  id?: number;
  type: PersonType;
  name?: string;
  inn?: string;
  ogrn?: string;
  kpp?: string;
  fullName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  address?: string;
  countryCode: string;
}

export interface PersonCompanyInterface extends PersonInterface {
  type: PersonType.COMPANY;
  name: string;
  inn: string;
  ogrn: string;
  kpp: string;
  address: string;
}

export interface PersonEntrepreneurInterface extends PersonInterface {
  type: PersonType.ENTREPRENEUR;
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  inn: string;
  ogrn: string;
}

export interface PersonIndividualInterface extends PersonInterface {
  type: PersonType.INDIVIDUAL;
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

export enum PersonType {
  COMPANY,
  ENTREPRENEUR,
  INDIVIDUAL
}
