import baseApi from "@/api/baseApi";
import { DaDataAddressItemInterface } from "@/models/dadata/DaDataAddressItem.interface";
import { DaDataOrganizationItemInterface } from "@/models/dadata/DaDataOrganizationItem.interface";
import { DaDataFullNameItemInterface } from "@/models/dadata/DaDataFullNameItem.interface";
import { DataResponseInterface } from "@/models/api/DataResponse.interface";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import { AxiosResponse } from "axios";

export async function getAddressSuggestions(
  query: string
): Promise<DaDataAddressItemInterface[]> {
  const res: AxiosResponse<
    DataResponseInterface<DaDataAddressItemInterface[]> | ErrorResponseInterface
  > = await baseApi.get(
    `/suggest/getFullAddressSuggestions?address=${query}&fromBound=city&toBound=house`
  );

  return res.data.isSuccess ? res.data.entity : [];
}

export async function getOrganizationByInn(
  inn: string
): Promise<DaDataOrganizationItemInterface | null> {
  const res: AxiosResponse<
    | DataResponseInterface<DaDataOrganizationItemInterface>
    | ErrorResponseInterface
  > = await baseApi.get(`/suggest/getOrganizationByInn?query=${inn}`);

  return res.data.entity;
}

export async function getOrganizationsByInn(
  inn: string,
  signal: AbortSignal
): Promise<DaDataOrganizationItemInterface[] | null> {
  const res: AxiosResponse<
    | DataResponseInterface<DaDataOrganizationItemInterface[]>
    | ErrorResponseInterface
  > = await baseApi.get(`/suggest/getOrganizationsByInn?query=${inn}`, {
    signal
  });

  if (res == null) return [];

  return res.data.entity;
}

export async function getFullNameData(
  fullName: string
): Promise<
  DataResponseInterface<DaDataFullNameItemInterface[]> | ErrorResponseInterface
> {
  return (await baseApi.get(`/suggest/fioSuggestions?query=${fullName}`)).data;
}
