import {
  LoaderItemContractDataInterface,
  LoaderItemContractPartyInterface
} from "@/models/person/LoaderItem.interface";
import { PersonType } from "@/models/person/Person.interface";

export class LoaderContractDataModel
  implements LoaderItemContractDataInterface {
  contractDate: string;
  number: string;
  parties: LoaderItemContractPartyInterface[];
  title: string;

  constructor() {
    this.title = "";
    this.number = "";
    this.contractDate = "";
    this.parties = [
      {
        inn: "",
        type: PersonType.COMPANY
      },
      {
        inn: "",
        type: PersonType.COMPANY
      }
    ];
  }
}
