import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import {
  AddressObjectInterface,
  LoadingAddressObjectInterface
} from "@/models/order/AddressObject.interface";
import LoadingAddressObjectModel from "@/models/order/LoadingAddressObject.model";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import DimensionsFormModel from "@/models/order/DimensionsForm.model";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";
import { PlaceOptionsModel } from "@/models/order/PlaceOptions.model";
import AddressObjectModel from "@/models/order/AddressObject.model";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";

export default class PlaceItemModel implements PlaceItemInterface {
  count: number;
  tax: string;
  name: string;
  barcode: string;
  comment: string;
  assessedValue: string;
  deliveryPrice: string;
  dimensions: DimensionsFormInterface;
  loadingAddress: LoadingAddressObjectInterface;
  unloadingAddress: AddressObjectInterface;
  options: OrderOptionsItemInterface;

  constructor() {
    this.count = 0;
    this.barcode = "";
    this.name = "";
    this.loadingAddress = new LoadingAddressObjectModel();
    this.unloadingAddress = new LoadingAddressObjectModel();
    this.dimensions = new DimensionsFormModel();
    this.assessedValue = "";
    this.deliveryPrice = "";
    this.tax = "";
    this.comment = "";
    this.options = new PlaceOptionsModel();
  }

  addLoadingAddress(
    datePeriod?: PeriodItemInterface,
    address?: YandexMapAddressItemInterface
  ) {
    this.loadingAddress = new LoadingAddressObjectModel(datePeriod, address);
  }

  addUnloadingAddress(
    datePeriod?: PeriodItemInterface,
    address?: YandexMapAddressItemInterface
  ) {
    this.unloadingAddress = new AddressObjectModel(datePeriod, address);
  }
}
