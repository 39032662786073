import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";

export default class OrderOptionsItemModel
  implements OrderOptionsItemInterface {
  dangerousClassCode: string;
  bodyTypes: string[];
  bodyTypeComment: string;
  cargoCategories: string[];
  cargoSubCategory: string;
  loadingTypes: string[];
  temperatureRegimes: string[];
  fillFullVolume: boolean;
  fillFullAssessedValue: boolean;
  wouldBeLoaded: boolean;
  allInSingleTransport: boolean;
  cargoCondition: string;
  cargoType: string;
  cargoShippingTitle: string;
  containerType: string;
  packingMethod: string;

  constructor() {
    this.bodyTypes = [];
    this.bodyTypeComment = "";
    this.cargoCategories = [];
    this.cargoSubCategory = "";
    this.loadingTypes = [];
    this.temperatureRegimes = [];
    this.cargoCondition = "";
    this.cargoType = "";
    this.cargoShippingTitle = "";
    this.dangerousClassCode = "";
    this.containerType = "";
    this.packingMethod = "";
    this.fillFullVolume = true;
    this.fillFullAssessedValue = true;
    this.wouldBeLoaded = false;
    this.allInSingleTransport = false;
  }
}
