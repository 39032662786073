import { defineStore } from "pinia";
import { AutocompleteStateInterface } from "@/models/store/AutocompleteState.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import {
  getCargoConditions,
  getOrganization,
  getPackingMethods
} from "@/api/autocomplete";
import { OrganizationInterface } from "@/models/store/Organization.interface";
import PersonService from "@/models/person/Person.service";

export const useAutocompleteStore = defineStore<
  "autocomplete",
  AutocompleteStateInterface
>("autocomplete", {
  state(): AutocompleteStateInterface {
    return {
      customerList: [],
      recipientList: [],
      senderList: [],
      loadingOwnerList: [],
      loaderList: [],
      packingMethods: [],
      cargoConditions: []
    };
  },
  actions: {
    async fetchOrganization({
      type,
      parentId = ""
    }: {
      type: OrganizationTypeEnum;
      parentId: string;
    }) {
      if (
        (type === OrganizationTypeEnum.Sender ||
          type === OrganizationTypeEnum.Recipient) &&
        !parentId
      ) {
        return;
      }

      const res = await getOrganization(type, parentId);

      if (!res.isSuccess) return;

      switch (type) {
        case OrganizationTypeEnum.Customer:
          this.UPDATE_ORGANIZATION({
            organization: "customerList",
            data: res.entity
          });
          break;
        case OrganizationTypeEnum.Sender:
          this.UPDATE_ORGANIZATION({
            organization: "senderList",
            data: res.entity
          });
          break;
        case OrganizationTypeEnum.Recipient:
          this.UPDATE_ORGANIZATION({
            organization: "recipientList",
            data: res.entity
          });
          break;
        case OrganizationTypeEnum.Loader:
          this.UPDATE_ORGANIZATION({
            organization: "loaderList",
            data: res.entity
          });
          break;
        case OrganizationTypeEnum.LoadingOwner:
          this.UPDATE_ORGANIZATION({
            organization: "loadingOwnerList",
            data: res.entity
          });
          break;
      }
    },
    UPDATE_ORGANIZATION({
      organization,
      data
    }: {
      organization: "customerList" | "senderList" | "recipientList";
      data: OrganizationInterface[];
    }) {
      this[organization] = data.map(
        PersonService.createPersonItemFromOrganization
      );
    },
    async fetchPackingMethods(parentId: number): Promise<void> {
      const res = await getPackingMethods(parentId);

      if (!res.isSuccess) return;

      this.packingMethods = res.entity.map(x => x.packingMethod);
    },
    async fetchCargoConditions(parentId: number): Promise<void> {
      const res = await getCargoConditions(parentId);

      if (!res.isSuccess) return;

      this.cargoConditions = res.entity.map(x => x.cargoCondition);
    }
  }
});
