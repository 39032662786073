import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { format } from "date-fns";

export const getFormattedDateTime = (datePeriod: PeriodItemInterface) => {
  if (!datePeriod.dateFrom) return "";
  const formattedDateFrom = format(new Date(datePeriod.dateFrom), "dd.MM.yyyy");
  const formattedDateTo = datePeriod.dateTo
    ? format(new Date(datePeriod.dateTo), "dd.MM.yyyy")
    : null;

  if (!datePeriod.isInterval) {
    return `${formattedDateFrom}, ${datePeriod.hourFrom}`;
  }

  if (formattedDateFrom === formattedDateTo) {
    return `${formattedDateFrom}, ${datePeriod.hourFrom} - ${datePeriod.hourTo}`;
  }

  if (!formattedDateTo) {
    return `${formattedDateFrom}, ${datePeriod.hourFrom}`;
  }

  return `${formattedDateFrom}, ${datePeriod.hourFrom} - ${formattedDateTo}, ${datePeriod.hourTo}`;
};
export const getAddressKey = (
  fullAddress: string,
  datePeriod: PeriodItemInterface
) => {
  return fullAddress + getFormattedDateTime(datePeriod);
};
