import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";
import AddressObjectModel from "@/models/order/AddressObject.model";
import { LoaderItemInterface } from "@/models/person/LoaderItem.interface";
import { LoaderItemModel } from "@/models/person/LoaderItem.model";
import { LoadingOwnerItemInterface } from "@/models/person/LoadingOwnerItem.interface";
import { LoadingOwnerItemModel } from "@/models/person/LoadingOwnerItem.model";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";

export default class LoadingAddressObjectModel extends AddressObjectModel
  implements LoadingAddressObjectInterface {
  loader: LoaderItemInterface;
  loadingOwner: LoadingOwnerItemInterface;

  constructor(
    datePeriod?: PeriodItemInterface,
    address?: YandexMapAddressItemInterface,
    person?: PersonItemInterface
  ) {
    super(datePeriod, address, person);
    this.loader = new LoaderItemModel();
    this.loadingOwner = new LoadingOwnerItemModel();
  }
}
