import PersonItemModel from "@/models/person/PersonItem.model";
import { LoadingOwnerItemInterface } from "@/models/person/LoadingOwnerItem.interface";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import ContactItemModel from "@/models/order/ContactItem.model";

export class LoadingOwnerItemModel extends PersonItemModel
  implements LoadingOwnerItemInterface {
  contacts: ContactItemInterface[];
  isNoData: boolean;
  isSender: boolean;
  noDataReason: string;

  constructor() {
    super();
    this.isSender = false;
    this.isNoData = false;
    this.noDataReason = "";
    this.contacts = [new ContactItemModel()];
  }

  addContact() {
    this.contacts.push(new ContactItemModel());
  }
  removeContact(contactIdx: number): void {
    if (this.contacts.length <= 1) return;

    this.contacts.splice(contactIdx, 1);
  }
}
