import {
  AuthorityToLoadEnum,
  LoaderItemContractDataInterface,
  LoaderItemEmployeeInterface
} from "@/models/person/LoaderItem.interface";
import { LoaderContractDataModel } from "@/models/person/LoaderContractData.model";

export class LoaderEmployeeModel implements LoaderItemEmployeeInterface {
  fullName: string;
  position: string;
  authorityToLoad: AuthorityToLoadEnum;
  contractData: LoaderItemContractDataInterface;
  constructor() {
    this.fullName = "";
    this.position = "";
    this.authorityToLoad = AuthorityToLoadEnum.JobResponsibilities;
    this.contractData = new LoaderContractDataModel();
  }
}
