import { ConditionsInterface } from "@/models/order/Conditions.interface";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import { ExecutorRoleTypeEnum } from "@/models/global/OrganizationView.interface";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";

export class ConditionsModel implements ConditionsInterface {
  otherConditions: string;
  documentExchangeDaysAfterFreightUnloading: string;
  documentsFormat: string;
  defermentOfPaymentDays: string;
  defermentOfPaymentDaysIsRequired: () => boolean;
  documentExchangeDaysAfterFreightUnloadingIsRequired: () => boolean;
  documentsFormatIsRequired: () => boolean;

  constructor(order: OrderItemInterface) {
    this.defermentOfPaymentDays = "";
    this.otherConditions = "";
    this.documentsFormat = "";
    this.documentExchangeDaysAfterFreightUnloading = "";

    this.defermentOfPaymentDaysIsRequired = () => {
      const selectedNotPartners =
        order.forwardToOrganizations?.filter(
          item =>
            !item.genContractId ||
            (order.executorRoleType === ExecutorRoleTypeEnum.Any &&
              item.partnerExecutorRoleType !== ExecutorRoleTypeEnum.Any)
        ) ?? [];
      return (
        order.orderType === OrderTypeEnum.ToHub ||
        (order.orderType === OrderTypeEnum.ToExecutor &&
          selectedNotPartners.length > 0)
      );
    };
    this.documentExchangeDaysAfterFreightUnloadingIsRequired = () => {
      const selectedNotPartners =
        order.forwardToOrganizations?.filter(
          item =>
            !item.genContractId ||
            (order.executorRoleType === ExecutorRoleTypeEnum.Any &&
              item.partnerExecutorRoleType !== ExecutorRoleTypeEnum.Any)
        ) ?? [];
      return (
        order.orderType === OrderTypeEnum.ToHub ||
        (order.orderType === OrderTypeEnum.ToExecutor &&
          selectedNotPartners.length > 0)
      );
    };
    this.documentsFormatIsRequired = () => {
      const selectedNotPartners =
        order.forwardToOrganizations?.filter(
          item =>
            !item.genContractId ||
            (order.executorRoleType === ExecutorRoleTypeEnum.Any &&
              item.partnerExecutorRoleType !== ExecutorRoleTypeEnum.Any)
        ) ?? [];
      return (
        order.orderType === OrderTypeEnum.ToHub ||
        (order.orderType === OrderTypeEnum.ToExecutor &&
          selectedNotPartners.length > 0)
      );
    };
  }
}
