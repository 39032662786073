import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import returnSplitName from "@/mixins/returnSplitName";
import { ContactInterface } from "@/models/order/Contact.interface";

export default class ContactService {
  static returnContactWithSplitName(
    contactItem: ContactItemInterface
  ): ContactInterface {
    const data = returnSplitName(contactItem.fullName);

    return {
      ...contactItem,
      ...data,
      additional: "",
      countryCode: contactItem.countryCode
    };
  }
}
