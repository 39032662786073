import { PersonInterface, PersonType } from "@/models/person/Person.interface";
import { getOrganizationByInn, getOrganizationsByInn } from "@/api/dadata";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PersonItemModel from "@/models/person/PersonItem.model";
import returnSplitName from "@/mixins/returnSplitName";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import {
  OrganizationCompanyInterface,
  OrganizationEntrepreneurInterface,
  OrganizationInterface
} from "@/models/store/Organization.interface";
import { DaDataOrganizationItemInterface } from "@/models/dadata/DaDataOrganizationItem.interface";

import { useAutocompleteStore } from "@/store/useAutocompleteStore";

export default class PersonService {
  static async searchPersonByInn(
    inn: string
  ): Promise<OrganizationInterface | null> {
    if (!/^\d{10,12}$/.test(inn)) return null;

    const organization = await getOrganizationByInn(inn);

    if (!organization) return null;

    return this.mapDaDataOrgToPerson(organization);
  }

  private static mapDaDataOrgToPerson(
    organization: DaDataOrganizationItemInterface
  ): OrganizationInterface {
    if (organization.type === PersonType.COMPANY) {
      return {
        id: 0,
        name: organization.name.full,
        inn: organization.inn,
        ogrn: organization.ogrn,
        kpp: organization.kpp,
        type: organization.type,
        address: organization.address.value,
        fullName: "",
        countryCode: "RUS"
      };
    }

    const [lastName, firstName, middleName] = organization.name.full.split(" ");

    return {
      id: 0,
      name: "",
      inn: organization.inn,
      ogrn: organization.ogrn,
      kpp: "",
      type: organization.type,
      address: organization.address.value,
      fullName: organization.name.full,
      lastName: lastName,
      firstName: firstName,
      middleName: middleName,
      countryCode: "RUS"
    };
  }

  static async searchPersonsByInn(
    inn: string,
    signal: AbortSignal
  ): Promise<OrganizationInterface[]> {
    if (!/^\d{0,12}$/.test(inn)) return [];

    const organizations = await getOrganizationsByInn(inn, signal);

    if (organizations == null) return [];

    return organizations.map(this.mapDaDataOrgToPerson);
  }

  static async setPersonCountryCode(
    person: PersonItemInterface,
    countryCode: string
  ) {
    person.countryCode = countryCode;
  }

  static async changePersonType(person: PersonItemInterface, type: PersonType) {
    person.clearData();

    person.type = type;
  }

  static async updatePerson(
    person: PersonItemInterface,
    updData: PersonItemInterface,
    organizationType: OrganizationTypeEnum
  ) {
    person.updateData(updData);

    if (organizationType !== OrganizationTypeEnum.Customer) return;
    const autocompleteStore = useAutocompleteStore();

    await Promise.all([
      autocompleteStore.fetchOrganization({
        type: OrganizationTypeEnum.Sender,
        parentId: person.id
      }),
      autocompleteStore.fetchOrganization({
        type: OrganizationTypeEnum.Recipient,
        parentId: person.id
      }),
      autocompleteStore.fetchOrganization({
        type: OrganizationTypeEnum.Loader,
        parentId: person.id
      }),
      autocompleteStore.fetchOrganization({
        type: OrganizationTypeEnum.LoadingOwner,
        parentId: person.id
      }),
      autocompleteStore.fetchCargoConditions(person.id),
      autocompleteStore.fetchPackingMethods(person.id)
    ]);
  }

  static returnPersonWithSplitName<
    T extends PersonItemInterface,
    TR extends PersonInterface
  >(person: T): T & TR {
    if (person.type === PersonType.COMPANY)
      return {
        ...person,
        name: person.name,
        inn: person.inn,
        ogrn: person.ogrn,
        kpp: person.kpp,
        address: person.address,
        type: person.type,
        countryCode: person.countryCode
      } as T & TR;

    const data = returnSplitName(person.fullName);

    return {
      ...person,
      lastName: data.lastName,
      firstName: data.firstName,
      middleName: data.middleName,
      inn: person.inn,
      ogrn: person.ogrn,
      kpp: person.kpp,
      address: person.address,
      type: person.type,
      countryCode: person.countryCode
    } as T & TR;
  }

  static createPersonItemFromOrganization(
    org: OrganizationInterface
  ): PersonItemInterface {
    const person = new PersonItemModel();

    switch (org.type) {
      case PersonType.COMPANY: {
        const orgCompany = org as OrganizationCompanyInterface;
        person.inn = orgCompany.inn;
        person.kpp = orgCompany.kpp ?? "";
        person.ogrn = orgCompany.ogrn ?? "";
        person.name = orgCompany.name;
        person.type = orgCompany.type;
        person.address = orgCompany.address;
        break;
      }
      case PersonType.ENTREPRENEUR: {
        const orgEntrepreneur = org as OrganizationEntrepreneurInterface;
        person.inn = orgEntrepreneur.inn;
        person.ogrn = orgEntrepreneur.ogrn ?? "";
        person.type = orgEntrepreneur.type;
        person.lastName = orgEntrepreneur.lastName;
        person.firstName = orgEntrepreneur.firstName;
        person.middleName = orgEntrepreneur.middleName;
        person.fullName = `${orgEntrepreneur.lastName} ${orgEntrepreneur.firstName} ${orgEntrepreneur.middleName}`;
        person.address = orgEntrepreneur.address ?? "";
        break;
      }
    }

    person.id = org.id;
    person.countryCode = org.countryCode ?? "RUS";

    return person;
  }
}
