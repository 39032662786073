import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import { YandexMapAddressItemModel } from "@/models/yandex-map/YandexMapAddressItem.model";
import { geocodeAddress } from "@/api/order";

export class YandexMapService {
  static async geocode(
    query: string
  ): Promise<YandexMapAddressItemInterface[]> {
    try {
      const suggestions = await geocodeAddress(query);

      if (suggestions.length === 0) return [];

      return suggestions.map(suggestion => {
        const daDataAddressItem = new YandexMapAddressItemModel();

        daDataAddressItem.data = suggestion.data;
        daDataAddressItem.value = suggestion.value;

        return daDataAddressItem;
      });
    } catch (e) {
      return [];
    }
  }
}
